<template>
  <CommonPage id="terms" title="Terms of use">
    <section class="terms-content">
      <p>
        Welcome to our news and information platform! By accessing or using our services, you agree
        to comply with and be bound by the following Terms of Service. Please read these terms
        carefully before using our platform.
      </p>

      <ol>
        <li>Use of Services</li>
      </ol>

      <p>
        You agree to use our news and information platform solely for lawful purposes and in
        compliance with all applicable laws and regulations. You must not engage in any activity
        that interferes with or disrupts the functioning of the platform or its services.
      </p>

      <ol>
        <li>User Accounts</li>
      </ol>

      <p>
        To access certain features of our platform, you may need to create a user account. You are
        responsible for maintaining the confidentiality of your account credentials and for all
        activities that occur under your account.
      </p>

      <ol>
        <li>Content</li>
      </ol>

      <p>
        Our platform may include user-generated content, third-party content, and proprietary
        content owned or licensed by us. You agree not to reproduce, distribute, modify, or create
        derivative works of any content without prior authorization.
      </p>

      <ol>
        <li>Intellectual Property</li>
      </ol>

      <p>
        All intellectual property rights in our platform, including trademarks, copyrights, and
        proprietary information, belong to us or our licensors. You may not use our intellectual
        property without our express written permission.
      </p>

      <ol>
        <li>Third-Party Links</li>
      </ol>

      <p>
        Our platform may contain links to third-party websites or services. We are not responsible
        for the content, privacy practices, or actions of third parties and recommend reviewing
        their terms and policies.
      </p>

      <ol>
        <li>Disclaimer of Warranties</li>
      </ol>

      <p>
        Our platform is provided on an &quot;as is&quot; and &quot;as available&quot; basis without
        warranties of any kind, either express or implied. We do not guarantee the accuracy,
        completeness, or reliability of any information or content on the platform.
      </p>

      <ol>
        <li>Limitation of Liability</li>
      </ol>

      <p>
        In no event shall we be liable for any direct, indirect, incidental, special, or
        consequential damages arising out of or in connection with your use of our platform. This
        includes damages for loss of profits, data, or goodwill.
      </p>

      <ol>
        <li>Indemnification</li>
      </ol>

      <p>
        You agree to indemnify and hold us harmless from any claims, damages, liabilities, or
        expenses arising out of your use of our platform or any violation of these Terms of Service.
      </p>

      <ol>
        <li>Governing Law</li>
      </ol>

      <p>
        These Terms of Service are governed by the laws of [Jurisdiction], without regard to its
        conflict of laws principles. Any disputes arising from these terms shall be subject to the
        exclusive jurisdiction of the courts in [Jurisdiction].
      </p>

      <ol>
        <li>Changes to Terms</li>
      </ol>

      <p>
        We reserve the right to update or modify these Terms of Service at any time. Changes will
        be effective upon posting on the platform. Your continued use of the platform after changes
        signifies your acceptance of the revised terms.
      </p>

      <p>
        If you have any questions or concerns about these Terms of Service, please contact us at
        [service_support@gmail.com].
      </p>
    </section>
  </CommonPage>
</template>

<script>
import '@/css/news/privacyterms.scss';
import CommonPage from './CommonPage.vue';
export default {
  components: {
    CommonPage
  },
};
</script>
